import { Link } from 'gatsby';
import React, { useState } from 'react';
import Cookies from 'js-cookie';
import Button from '../../components/Button';
import Input from '../../components/Form/Input';
import LoginWrap from '../../components/LoginWrap';
import { CONSOLE_URL, LOGIN_TYPE, LOGIN_TYPE_ENUM, DOMAIN } from '../../constants';
import { iamLogin, login } from '../../services';
import { IAMLoginBody, LoginBody, LoginResult } from '../../services/interface';

import * as styles from './index.module.less';
import { toConsole } from '../../utils';
import SEO from '../../components/SEO';

// 这个注释不要删除
console.log('domain: ', DOMAIN);

function successCallback(data: LoginResult, username: string) {
  const { token, plus_token, console_url } = data;
  // 清除掉之前的 cookie
  Cookies.remove('token');
  Cookies.remove('plus_token');
  Cookies.remove('token', { domain: DOMAIN });
  Cookies.remove('plus_token', { domain: DOMAIN });

  // cookie 信息存储并指向设置的域
  Cookies.set('token', token, { domain: DOMAIN });
  Cookies.set('plus_token', plus_token, { domain: DOMAIN });

  // 控制台展示
  Cookies.set('SATURN_LOGIN_USERNAME', username, { domain: DOMAIN });
  Cookies.set(CONSOLE_URL, console_url, { domain: DOMAIN });

  // 当前页面跳转到 minio 控制台
  toConsole('WEBSITE_LOGIN');
}
export default function LoginPage({ location }: { location: Location }) {
  const query = new URLSearchParams(location.search);

  const [loading, setLoading] = useState(false);
  const [btnText, setBtnText] = useState('登 录');
  const [tabActive, setTabActive] = useState<LOGIN_TYPE_ENUM>(LOGIN_TYPE.NORMAL.id);
  const [formValue, setFormValue] = useState<LoginBody>({
    user_name: query.get('username') || '',
    password: query.get('password') || '',
  });
  const [iamFormValue, setIAMFormValue] = useState<IAMLoginBody>({
    user_name: '',
    sub_account_name: '',
    sub_account_password: '',
  });

  function submitHandler(e: React.FormEvent) {
    e.preventDefault();
    if (loading) return;
    setLoading(true);
    if (tabActive === LOGIN_TYPE.NORMAL.id) {
      login(formValue)
        .then(({ data }) => {
          setBtnText('跳转中...');
          successCallback(data.data, formValue.user_name);
        })
        .catch(() => {
          setLoading(false);
        });
    }
    if (tabActive === LOGIN_TYPE.IAM.id) {
      iamLogin(iamFormValue)
        .then(({ data }) => {
          setBtnText('跳转中...');
          successCallback(data.data, iamFormValue.sub_account_name);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  }

  return (
    <LoginWrap
      title="登录"
      otherLink={
        <>
          <Link to="/password">忘记密码</Link>
          <Link to="/register">免费注册</Link>
        </>
      }>
      <div className={styles.tab}>
        {Object.values(LOGIN_TYPE).map((item) => {
          const cls = [styles.item, item.id === tabActive && styles.active]
            .filter((i) => !!i)
            .join(' ');
          return (
            <div
              key={item.id}
              className={cls}
              onClick={() => {
                setTabActive(item.id);
              }}>
              {item.cname}
            </div>
          );
        })}
      </div>
      <form onSubmit={submitHandler}>
        {tabActive === LOGIN_TYPE.NORMAL.id && (
          <>
            <div className={styles.formItem}>
              <Input
                type="text"
                name="user_name"
                required
                placeholder="手机号 / 邮箱"
                value={formValue.user_name}
                onChange={(e) => {
                  setFormValue((state) => ({
                    ...state,
                    user_name: e.target.value,
                  }));
                }}
              />
            </div>
            <div className={styles.formItem}>
              <Input
                type="password"
                name="password"
                required
                placeholder="密码"
                value={formValue.password}
                onChange={(e) => {
                  setFormValue((state) => ({
                    ...state,
                    password: e.target.value,
                  }));
                }}
              />
            </div>
          </>
        )}
        {tabActive === LOGIN_TYPE.IAM.id && (
          <>
            <div className={styles.formItem}>
              <Input
                type="text"
                name="user_name"
                required
                placeholder="主账户手机号 / 邮箱"
                value={iamFormValue.user_name}
                onChange={(e) => {
                  setIAMFormValue((state) => ({
                    ...state,
                    user_name: e.target.value,
                  }));
                }}
              />
            </div>
            <div className={styles.formItem}>
              <Input
                type="text"
                name="sub_account_name"
                required
                placeholder="子账户用户名"
                value={iamFormValue.sub_account_name}
                onChange={(e) => {
                  setIAMFormValue((state) => ({
                    ...state,
                    sub_account_name: e.target.value,
                  }));
                }}
              />
            </div>
            <div className={styles.formItem}>
              <Input
                type="password"
                name="sub_account_password"
                required
                placeholder="子账户密码"
                value={iamFormValue.sub_account_password}
                onChange={(e) => {
                  setIAMFormValue((state) => ({
                    ...state,
                    sub_account_password: e.target.value,
                  }));
                }}
              />
            </div>
          </>
        )}
        <div className={styles.formItem}>
          <Button type="primary" htmlType="submit" block loading={loading}>
            {btnText}
          </Button>
        </div>
      </form>
    </LoginWrap>
  );
}

export function Head() {
  return <SEO title="登录" />;
}
